.App {
  text-align: center;
}

.image-selectors {
  margin: 60px 0;
}

.file-upload {
  margin-top: 40px;
}

.single-device-displays {
  margin-top: 40px;
  margin-bottom: 40px;
}
