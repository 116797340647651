.upload-file-label {
  font-weight: bold;
  margin-bottom: 0;
}

.custom-file-label {
  transform: translateY(30px);
}

.custom-file-input:hover,
.custom-file-label:hover {
  cursor: pointer;
}
